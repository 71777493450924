import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BidddyToggle from "../../../../../Inputs/BidddyToggle";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import BasicTableHeader from "../../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import getSizingData from "../../../../../Inputs/internal/getSizingData";
import calculateNodeHeight from "../../../../../Inputs/internal/calculateNodeHeight";
import styles from "./AuctionComponents.module.scss";
import { updateAuction } from "../../../../../../store/auctionModal/reducer";
import { getAuction } from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import { useAppDispatch } from "../../../../../../store";
import { getCurrencySymbol } from "../../../../../../utils/helpers";

/*
Types of description items
{
  "common": ""
  "info": ...
  "how": ...
  "rules": ...
  "shipping": ...
  "payment": ...
  "footer": ...
},
*/

const DEFAULT_OPTIONAL_BIDDING_COPY = (use_bin = false, auction) =>
  `“Bid ${getCurrencySymbol(auction.currency)}XX” or 
  “${getCurrencySymbol(auction.currency)}XX” in comments to Bid.
  ${use_bin ? '\nComment "BIN" to Buy It Now' : ""} \n
  “What is Highest Bid” for highest bid.\n\nWinning bidder will be contacted via Direct Message after auction close.\n\nShipping in Canada: 
  ${getCurrencySymbol(auction.currency)}XX\nShipping in US: 
  ${getCurrencySymbol(auction.currency)}XX
  \n\nSeller accepts etransfer and PayPal.\nPayment required within XX hours.\n\nPowered by Bidddy @getbidddy`;

const AuctionBiddingInstructions = () => {
  const dispatch = useAppDispatch();
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const [currentCurrency, setCurrentCurrency] = useState(
    auction && auction.currency ? auction.currency : "CAD"
  );

  const [isOptionalBiddingInstructionsOn, setIsOptionalBiddingInstructionsOn] =
    useState(false);

  const [optionalBiddingInstructions, setOptionalBiddingInstructions] =
    useState(DEFAULT_OPTIONAL_BIDDING_COPY(auction.use_bin, auction));
  const [maxHeight, setMaxHeight] = useState(336);

  useEffect(() => {
    if (isOptionalBiddingInstructionsOn) {
      const auct = JSON.parse(JSON.stringify(auction)) as Auction;
      auct.description = {
        ...auct.description,
        info: DEFAULT_OPTIONAL_BIDDING_COPY(auction.use_bin, auction),
      };

      Object.preventExtensions(auct);
      dispatch(updateAuction(auct));
    } else {
      const auct = JSON.parse(JSON.stringify(auction)) as Auction;
      auct.description = { ...auct.description, info: "" };

      Object.preventExtensions(auct);
      dispatch(updateAuction(auct));
    }
  }, [isOptionalBiddingInstructionsOn]);

  useEffect(() => {
    if (auction && auction.currency !== currentCurrency) {
      setCurrentCurrency(auction.currency);
      if (auction.description && auction.description.info) {
        setOptionalBiddingInstructions(auction.description.info);
      }
    }
    if (auction.description) {
      if (auction.description.info) {
        setOptionalBiddingInstructions(auction.description.info);
      }
    }
  }, []);

  useEffect(() => {
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;

    if (isOptionalBiddingInstructionsOn) {
      auct.description = {
        ...auct.description,
        info: optionalBiddingInstructions,
      };
    }
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  }, [currentCurrency]);

  useEffect(() => {
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    if (!auct) {
      return;
    }
    if (auct) {
      if (auct.description) {
        if (auct.description.info) {
          setOptionalBiddingInstructions(auct.description.info);
          setIsOptionalBiddingInstructionsOn(true);
        }
      }
    }
  }, [auction]);

  const updateDescription = () => {
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    if (auct.description && auct.description.info) {
      auct.description.info = optionalBiddingInstructions;
    } else {
      auct.description = { info: optionalBiddingInstructions };
    }

    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const handleChange = (e) => {
    const sizeData = getSizingData(e.target);
    const height = calculateNodeHeight(sizeData, e.target.value);
    setMaxHeight(height[0]);
    setOptionalBiddingInstructions(e.target.value);
  };

  if (!auction) {
    return <></>;
  }

  return (
    <>
      <div className="mb-24">
        <BasicTable>
          <BasicTableHeader>
            <BasicTableRow
              onClick={() =>
                document.getElementById("optionalBiddingInstructions").focus()
              }
            >
              <td className="w-full" colSpan={3}>
                Bidding Instructions (Optional)
              </td>
              <td
                colSpan={1}
                className="text-right align-baseline w-1/5 relative"
              >
                <BidddyToggle
                  on={isOptionalBiddingInstructionsOn}
                  onClick={() =>
                    setIsOptionalBiddingInstructionsOn(
                      !isOptionalBiddingInstructionsOn
                    )
                  }
                />
              </td>
            </BasicTableRow>
          </BasicTableHeader>
          <BasicTableBody>
            <BasicTableRow
              hideBorderBottom
              onClick={() =>
                document.getElementById("optionalBiddingInstructions").focus()
              }
            >
              <td>
                <textarea
                  placeholder="Enter your auction description here."
                  id="optionalBiddingInstructions"
                  disabled={!isOptionalBiddingInstructionsOn}
                  className={styles.defaultTextArea}
                  value={optionalBiddingInstructions}
                  onChange={(e) => handleChange(e)}
                  onBlur={() => updateDescription()}
                  style={{
                    minHeight: maxHeight,
                    maxHeight,
                  }}
                />
              </td>
            </BasicTableRow>
          </BasicTableBody>
        </BasicTable>
      </div>
    </>
  );
};

export default AuctionBiddingInstructions;
