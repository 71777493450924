import React, { useEffect, useState } from "react";
import SlideOut from "@ijsto/react-slideout";
import { createPortal } from "react-dom";
import Application from "react-rainbow-components/components/Application";
import DateTimePicker from "react-rainbow-components/components/DateTimePicker";
import { useSelector } from "react-redux";
import { format } from "date-fns-tz";
import CurrencyInput from "react-currency-input-field";
import {
  getCurrencyLocaleValue,
  getCurrencySymbol,
} from "../../../../../../utils/helpers";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import BasicTableHeader from "../../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import ForwardArrow from "./ForwardArrow";
import styles from "./AuctionComponents.module.scss";
import { useAppDispatch } from "../../../../../../store";
import { updateAuction } from "../../../../../../store/auctionModal/reducer";
import { getAuction } from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import { MethodTypes } from "../../../../../../enums/MethodTypes";
import {
  selectAuctionSettings,
  selectUserInfo,
} from "../../../../../../store/user/selectors";
import {
  AuctionSettings,
  AccountSettingsRequestParams,
  getAuctionSettings,
} from "../../../../../../store/user/actions";
import { User } from "../../../../../../types/User";
import BidddyToggle from "../../../../../Inputs/BidddyToggle";
import { CurrencyOptions } from "../../../../../../enums/CurrencyTypes";

const theme = {
  rainbow: {
    palette: {
      mainBackground: "#FFFFFF",
      brand: "#F4799E",
      success: "#FFFFFF",
      warning: "#FFFFFF",
      error: "#FFFFFF",
    },
  },
};

const okButtonLocalizedLabel = {
  "en-US": "OK",
  "es-ES": "Aceptar",
  "fr-Fr": "D'accord",
};

const cancelButtonLocalizedLabel = {
  "en-US": "Cancel",
  "es-ES": "Cancelar",
  "fr-Fr": "Annuler",
};

const AuctionSettingsTable = () => {
  const dispatch = useAppDispatch();
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const auctionSettings = useSelector((state) =>
    selectAuctionSettings(state)
  ) as AuctionSettings;
  const [startDate, setStartDate] = useState(
    auction ? new Date(auction.starts_at) : new Date()
  );
  const [endDate, setEndDate] = useState(
    auction ? new Date(auction.ends_at) : new Date()
  );
  const [startingBidPrice, setStartingBidPrice] = useState(
    auction && auction.item && auction.item.price
      ? auction.item.price
      : auctionSettings.price || 10.0
  );
  const [bidIncrements, setBidIncrements] = useState(
    auction && auction.item && auction.item.price
      ? auction.item.increment
      : auctionSettings.increment || 1.0
  );
  const [buyItNowPrice, setBuyItNowPrice] = useState(
    auction && auction.item && auction.item.bin_price
      ? auction.item.bin_price
      : 10.0
  );
  const [currency, setCurrency] = useState(
    auctionSettings.currency || CurrencyOptions[0]
  );
  const [displayBidPriceModal, setDisplayBidPriceModal] = useState(false);
  const [displayBidIncrements, setDisplayBidIncrements] = useState(false);
  const [displayCurrencyOptions, setDisplayCurrencyOptions] = useState(false);
  const [displayBinPrice, setDisplayBinPrice] = useState(false);
  const [isBuyItNowOn, setIsBuyItNowOn] = useState(
    (auction && auction.use_bin) || false
  );
  const [slideOutOpen, setSlideOutOpen] = useState(false);

  useEffect(() => {
    if (userInfo) {
      const params = {
        store_id: userInfo.data.last_store_id,
      } as AccountSettingsRequestParams;
      dispatch(getAuctionSettings(params));
    }
  }, [userInfo]);

  const updateStartDate = (value) => {
    setStartDate(value);
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.starts_at = value.toISOString();
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const updateEndDate = (value) => {
    setEndDate(value);
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.ends_at = value.toISOString();
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const updateStartingBid = (value) => {
    setStartingBidPrice(parseFloat(value));
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.item.price = parseFloat(value);
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const updateBidIncrements = (value) => {
    setBidIncrements(parseFloat(value));
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.item.increment = parseFloat(value);
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const updateCurrency = (value) => {
    setCurrency(value);
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.currency = value;
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const updateBinPrice = (value) => {
    setBuyItNowPrice(parseFloat(value));
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.item.bin_price = parseFloat(value);
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const onClose = () => {
    setSlideOutOpen(false);
  };

  const allowBIN = () => {
    if (isBuyItNowOn) {
      setDisplayBinPrice(true);
    }
  };

  const toggleBIN = () => {
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    if (isBuyItNowOn) {
      auct.use_bin = false;
      auct.item.bin_price = null;
    } else {
      auct.use_bin = true;
      auct.item.bin_price = buyItNowPrice;
    }
    setIsBuyItNowOn(!isBuyItNowOn);
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  return (
    <>
      <Application theme={theme}>
        <div className={styles.descriptionContainer}>
          <BasicTable>
            <BasicTableHeader>
              <BasicTableRow>
                <td className="w-full" colSpan={2}>
                  Auction Settings
                </td>
              </BasicTableRow>
            </BasicTableHeader>
            <BasicTableBody>
              {/* Starts */}
              <BasicTableRow>
                <td className="text-bidddyAltGray">
                  Starts{auction.type === MethodTypes.code && " *"}
                </td>
                <td className={styles.tableRowDatePicker}>
                  {auction.type === MethodTypes.schedule && (
                    <>
                      <DateTimePicker
                        id="datetimepicker-start"
                        variant="single"
                        className={styles.dateContainer}
                        value={startDate}
                        onChange={(value) => updateStartDate(value)}
                        formatStyle="medium"
                        okLabel={okButtonLocalizedLabel["en-US"]}
                        cancelLabel={cancelButtonLocalizedLabel["en-US"]}
                      />
                      <span
                        className="ml-2 text-bidddyAltGray text-sm min-w-max"
                        style={{ lineHeight: "30px" }}
                      >
                        {format(startDate, "zzz")}
                      </span>
                    </>
                  )}
                  {auction.type === MethodTypes.code && (
                    <p className={styles.startText}>Auto-Start Code</p>
                  )}
                </td>
              </BasicTableRow>
              <BasicTableRow>
                <td className="text-bidddyAltGray">Ends</td>
                <td className={styles.tableRowDatePicker}>
                  <DateTimePicker
                    variant="double"
                    id="datetimepicker-ends"
                    className={styles.dateContainer}
                    value={endDate}
                    onChange={(value) => updateEndDate(value)}
                    formatStyle="medium"
                    okLabel={okButtonLocalizedLabel["en-US"]}
                    cancelLabel={cancelButtonLocalizedLabel["en-US"]}
                  />
                  <span
                    className="ml-2 text-bidddyAltGray text-sm min-w-max"
                    style={{ lineHeight: "30px" }}
                  >
                    {format(endDate, "zzz")}
                  </span>
                </td>
              </BasicTableRow>
              <BasicTableRow onClick={() => setDisplayBidPriceModal(true)}>
                <td className="text-bidddyAltGray w-1/2 text-sm">
                  Starting Bid Price
                </td>
                <td className="text-right">
                  {getCurrencyLocaleValue(currency, startingBidPrice)}
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow onClick={() => setDisplayBidIncrements(true)}>
                <td className="text-bidddyAltGray">Bid Increments</td>
                <td className="text-right">
                  {getCurrencyLocaleValue(currency, bidIncrements)}
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow
                onClick={() =>
                  setDisplayCurrencyOptions(!displayCurrencyOptions)
                }
              >
                <td className="text-bidddyAltGray">Currency</td>
                <td className="text-right relative">
                  {currency}
                  <ForwardArrow onClick={() => {}} />
                  {displayCurrencyOptions && (
                    <div className={styles.selectCurrencyOptions}>
                      <div className={styles.currencyOptionsContainer}>
                        {CurrencyOptions.map((option) => (
                          <button
                            type="button"
                            onClick={() => updateCurrency(option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </td>
              </BasicTableRow>
              <BasicTableRow
                hideBorderBottom
                onClick={() => setSlideOutOpen(true)}
              >
                <td className="text-bidddyAltGray">Buy It Now Price</td>
                <td className="text-right">
                  {isBuyItNowOn
                    ? getCurrencyLocaleValue(currency, buyItNowPrice)
                    : "Off"}
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
            </BasicTableBody>
          </BasicTable>
          {displayBidPriceModal && (
            <div className={styles.bidPriceModal}>
              <div className={styles.bidPriceModalContainer}>
                <h3>Starting Bid Price</h3>
                <CurrencyInput
                  id="starting-bid"
                  name="starting bid"
                  prefix={getCurrencySymbol(currency)}
                  allowDecimals={false}
                  disableGroupSeparators
                  disableAbbreviations
                  allowNegativeValue={false}
                  placeholder="Enter your starting bid price"
                  defaultValue={startingBidPrice}
                  decimalsLimit={0}
                  onValueChange={(value) => updateStartingBid(value)}
                />
                <div className="flex flex-row mt-4 justify-center">
                  <button
                    type="button"
                    onClick={() => setDisplayBidPriceModal(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
          {displayBidIncrements && (
            <div className={styles.bidPriceModal}>
              <div className={styles.bidPriceModalContainer}>
                <h3>Bid Increments</h3>
                <CurrencyInput
                  id="increment-bid"
                  name="increment bid"
                  prefix={getCurrencySymbol(currency)}
                  allowDecimals={false}
                  disableGroupSeparators
                  disableAbbreviations
                  allowNegativeValue={false}
                  placeholder="Enter your bid increment"
                  defaultValue={bidIncrements}
                  decimalsLimit={0}
                  onValueChange={(value) => updateBidIncrements(value)}
                />
                <div className="flex flex-row mt-4 justify-center">
                  <button
                    type="button"
                    onClick={() => setDisplayBidIncrements(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {auction.type === MethodTypes.code && (
          <p className="text-sm">
            * Bidddy will respond to bids when the auto-start code is included
            in the description or posted as a comment.
          </p>
        )}
      </Application>
      {createPortal(
        <SlideOut
          noCloseComponent
          isOpen={slideOutOpen}
          onClose={() => onClose()}
          width="100%"
          slideFrom="right"
          offsetTop="0px"
        >
          <div className="bg-white w-full h-full flex flex-col">
            <div className="flex flex-row justify-center items-center w-full relative border-b border-solid p-4 border-bidddyLightGray">
              <h4 className=" text-bidddyAltGray font-inter text-base font-normal justify-self-center inline-flex">
                Buy It Now
              </h4>
              <button
                type="button"
                className="absolute right-4 font-inter text-bidddyPink font-medium"
                onClick={() => onClose()}
              >
                Done
              </button>
            </div>
            <div className={styles.advancedSettings}>
              <BasicTable>
                <BasicTableHeader>
                  <BasicTableRow hideBorderBottom>
                    <td
                      colSpan={2}
                      className="text-bidddyAltGray align-baseline"
                    >
                      <p>Buy It Now (BIN)</p>
                    </td>
                    <td
                      colSpan={1}
                      className="text-right align-baseline w-1/6 relative"
                    >
                      <BidddyToggle
                        on={isBuyItNowOn}
                        onClick={() => toggleBIN()}
                      />
                    </td>
                  </BasicTableRow>
                  <BasicTableRow>
                    <td className="w-full" colSpan={2}>
                      Adding Buy It Now (BIN) allows the buyer to purchase an
                      item at a fixed price without waiting for the auction to
                      end.
                      <br />
                      As the seller, you have control. You can decide to accept
                      the BIN or another bid as the winner.
                      <br />
                      <br />
                    </td>
                  </BasicTableRow>
                </BasicTableHeader>
                <BasicTableBody>
                  <BasicTableRow hideBorderBottom onClick={() => allowBIN()}>
                    <td colSpan={1}>
                      <span
                        className={
                          isBuyItNowOn
                            ? "text-bidddyAltGray"
                            : "text-bidddyGray"
                        }
                      >
                        Buy It Now Price
                      </span>
                    </td>
                    <td
                      colSpan={2}
                      className="text-right flex flex-row items-center justify-end w-full py-4"
                    >
                      <span
                        className={
                          isBuyItNowOn
                            ? "text-bidddyAltGray"
                            : "text-bidddyGray"
                        }
                      >
                        {getCurrencyLocaleValue(currency, buyItNowPrice)}
                      </span>
                      <span>
                        <ForwardArrow onClick={() => {}} />
                      </span>
                    </td>
                  </BasicTableRow>
                </BasicTableBody>
              </BasicTable>
            </div>
          </div>
          {displayBinPrice && (
            <div className={styles.bidPriceModal}>
              <div className={styles.bidPriceModalContainer}>
                <h3>Buy It Now Price</h3>
                <CurrencyInput
                  id="starting-bid"
                  name="starting bid"
                  prefix={getCurrencySymbol(currency)}
                  allowDecimals={false}
                  disableGroupSeparators
                  disableAbbreviations
                  allowNegativeValue={false}
                  placeholder="Enter your starting bid price"
                  defaultValue={buyItNowPrice}
                  decimalsLimit={0}
                  onValueChange={(value) => updateBinPrice(value)}
                />
                <div className="flex flex-row mt-4 justify-center">
                  <button
                    type="button"
                    onClick={() => setDisplayBinPrice(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
        </SlideOut>,
        document.getElementById("___gatsby")
      )}
    </>
  );
};

export default AuctionSettingsTable;
