import { navigate } from "gatsby";
import React from "react";
import { useSelector } from "react-redux";
import AuctionSettings from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/steps/AuctionSettings";
import { AuthProvider } from "../../../../components/Auth/auth";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import SimpleSpring from "../../../../components/Transitions/transitions";
import { getAuction } from "../../../../store/auctionModal/selectors";
import { Auction } from "../../../../types/Auction";
import DashboardLayout from "../../../../components/DashboardLayout";
import { MethodTypes } from "../../../../enums/MethodTypes";

const AuctionCreateAddSettings = ({ location }) => {
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const handleBack = () => {
    navigate("/dashboard/auctions/create/add-title/");
  };

  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        <DashboardLayout
          style={{ paddingTop: 0 }}
          hideNav
          hideFooter
          title="Bidddy | Create Auction - Auction Settings"
        >
          <AuctionCreationNav
            onBack={() => handleBack()}
            step={1}
            totalSteps={auction.type === MethodTypes.code ? 4 : 6}
            title="Auction Settings"
          />
          <AuctionSettings />
        </DashboardLayout>
      </SimpleSpring>
    </AuthProvider>
  );
};

export default AuctionCreateAddSettings;
