import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import calculateNodeHeight from "../../../../../Inputs/internal/calculateNodeHeight";
import getSizingData from "../../../../../Inputs/internal/getSizingData";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import BasicTableHeader from "../../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import { useAppDispatch } from "../../../../../../store";
import { updateAuction } from "../../../../../../store/auctionModal/reducer";
import { getAuction } from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import styles from "./AuctionComponents.module.scss";

const AuctionDescriptionTable = () => {
  const dispatch = useAppDispatch();
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const [description, setDescription] = useState(
    auction && auction.description && auction.description.common
      ? auction.description.common
      : ""
  );
  const [maxHeight, setMaxHeight] = useState(42);

  useEffect(() => {
    if (auction && auction.description && auction.description.common) {
      setDescription(auction.description.common);
    }
  }, [auction]);

  const updateDescription = () => {
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    if (auct.description && auct.description.common) {
      auct.description.common = description;
    } else {
      auct.description = { common: description };
    }

    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  const handleChange = (e) => {
    const sizeData = getSizingData(e.target);
    const height = calculateNodeHeight(sizeData, e.target.value);
    setMaxHeight(height[0]);
    setDescription(e.target.value);
  };
  // get text area text
  return (
    <BasicTable>
      <BasicTableHeader>
        <BasicTableRow
          onClick={() => document.getElementById("description").focus()}
        >
          <td>Item Description</td>
        </BasicTableRow>
      </BasicTableHeader>
      <BasicTableBody>
        {/* todo description editing (on blur save) */}
        <BasicTableRow
          hideBorderBottom
          onClick={() => document.getElementById("description").focus()}
        >
          <td>
            <textarea
              placeholder="Enter your auction description here."
              id="description"
              className={styles.defaultTextArea}
              value={description}
              onChange={(e) => handleChange(e)}
              onBlur={() => updateDescription()}
              style={{
                minHeight: maxHeight,
                maxHeight,
              }}
            />
          </td>
        </BasicTableRow>
      </BasicTableBody>
    </BasicTable>
  );
};

export default AuctionDescriptionTable;
