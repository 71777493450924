import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import cn from "classnames";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import styles from "./AuctionSteps.module.scss";
import AuctionSettingsTable from "../internal/AuctionSettingsTable";
import AuctionButtonsContainer from "../internal/AuctionButtonsContainer";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import { MethodTypes } from "../../../../../../enums/MethodTypes";
import { sendToast } from "../../../../../../utils/helpers";
import { useAppDispatch } from "../../../../../../store";
import { updateAuction } from "../../../../../../store/auctionModal/reducer";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
} from "../../../../../../store/user/selectors";
import { Market } from "../../../../../../types/Market";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../../../../store/user/actions";
import { LocationTypes } from "../../../../../Onboarding/ConnectBidddy";
import { AuctionPartial } from "../../../../../../store/auctionModal/actions";
import {
  AuctionCode,
  AuctionCodeRequestParams,
  generateAuctionCode,
  patchAuction,
  saveAuctionPartial,
  ScheduledAuction,
} from "../../../../../../store/auctions/actions";
import { event } from "../../../../../../lib/ga";
import {
  hasSuccessfullyPublished,
  recentlyCreatedAuction,
  selectAuctionCode,
  selectSavingDraft,
  successfullyDraftedAuction,
  successfullyUpdatedAuction,
} from "../../../../../../store/auctions/selectors";
import AuctionBiddingInstructions from "../internal/AuctionBiddingInstructions";
import AuctionDescriptionTable from "../internal/AuctionDescriptionTable";

const BidddyImage =
  "https://imgix.cosmicjs.com/3d375d20-c983-11ec-bea6-99436435ce05-BidfootBackground.png";

const AuctionSettings = () => {
  const dispatch = useAppDispatch();
  const success = useSelector((state) =>
    hasSuccessfullyPublished(state)
  ) as boolean;
  const successfullyUpdated = useSelector((state) =>
    successfullyUpdatedAuction(state)
  ) as boolean;
  const createdAuction = useSelector((state) =>
    recentlyCreatedAuction(state)
  ) as ScheduledAuction;
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const userInfo = useSelector((state) => selectUserInfo(state));
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const savingDraft = useSelector((state) => selectSavingDraft(state));
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const auctionCode = useSelector((state) =>
    selectAuctionCode(state)
  ) as AuctionCode;
  const hasDrafted = useSelector((state) =>
    successfullyDraftedAuction(state)
  ) as boolean;
  const [localLoading, setLocalLoading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState("");

  useEffect(() => {
    if (successfullyUpdated && createdAuction && !savingDraft) {
      event({
        action: "scheduled_auction_on_update_multi_image",
        params: {},
      });
      // Create Auction Code
      const auctionCodeParams = {
        storeId: userInfo.data.last_store_id,
        auctionId: createdAuction.id,
      } as AuctionCodeRequestParams;
      dispatch(generateAuctionCode(auctionCodeParams));
    }
  }, [successfullyUpdated]);

  useEffect(() => {
    if (success && createdAuction && !savingDraft) {
      event({
        action: "scheduled_auction_multi_image",
        params: {},
      });
      // CREATE AUCTION CODE
      const auctionCodeParams = {
        storeId: userInfo.data.last_store_id,
        auctionId: createdAuction.id,
      } as AuctionCodeRequestParams;
      dispatch(generateAuctionCode(auctionCodeParams));
    }
  }, [success, createdAuction]);

  useEffect(() => {
    if (auctionCode && auction && !hasDrafted) {
      if (isEditing) {
        navigate(`/dashboard/auctions/${auction.id}/success/`);
      } else {
        navigate("/dashboard/auctions/create/success/");
      }
      setLocalLoading(false);
    }
  }, [auctionCode]);

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  useEffect(() => {
    if (availableMarkets.length > 0) {
      const selectedAuctionMarket = availableMarkets.find(
        (market) => market.id === auction.market_id
      );
      if (selectedAuctionMarket) {
        setSelectedMarket(selectedAuctionMarket.name);
      }
    }
  }, [availableMarkets, auction]);

  const saveCurrentAuction = (auct: Auction) => {
    const auctionPartial = {
      userInfo,
      auction: auct,
    } as AuctionPartial;
    if (isEditing) {
      dispatch(patchAuction(auctionPartial));
    } else {
      dispatch(saveAuctionPartial(auctionPartial));
    }
  };

  const updateAuctionWithSettings = () => {
    setLocalLoading(true);
    const currentDate = new Date();
    if (
      auction.type === MethodTypes.schedule &&
      new Date(auction.starts_at) < currentDate
    ) {
      sendToast("Please set your start date past the current time", {
        type: "warning",
      });
      setLocalLoading(false);
      return;
    }
    if (
      auction.type === MethodTypes.schedule &&
      new Date(auction.ends_at) < new Date(auction.starts_at)
    ) {
      sendToast("Please set your end date AFTER your start date", {
        type: "warning",
      });
      setLocalLoading(false);
      return;
    }

    if (new Date(auction.ends_at) < currentDate) {
      sendToast("Please set your end date to AFTER the current date", {
        type: "warning",
      });
      setLocalLoading(false);
      return;
    }
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    if (!isEditing) {
      if (auction.type === MethodTypes.code) {
        // Handle save
        auct.progress = "draft";
        saveCurrentAuction(auct);
        // navigate('/dashboard/auctions/create/success/');
      } else {
        navigate("/dashboard/auctions/create/add-image/", {
          state: { navigationPath: LocationTypes.AuctionCreation },
        });
      }
    } else if (isEditing) {
      if (auction.type === MethodTypes.code) {
        auct.progress = "draft";
        saveCurrentAuction(auct);
        // navigate('/dashboard/auctions/create/success/');
      } else {
        navigate(`/dashboard/auctions/${auction.id}/add-image/`, {
          state: { navigationPath: LocationTypes.AuctionCreation },
        });
      }
    }
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));
  };

  useEffect(() => {
    if (availableMarkets.length === 0) {
      dispatch(getUserInstagramAccounts());
    }
  }, []);

  const getLearnText = () => {
    if (auction.type === MethodTypes.code) {
      return "How do Auto-Start Codes Work?";
    }
    return "";
  };

  const getLearnPath = () => {
    if (auction.type === MethodTypes.code) {
      return "/learn/activate-bidddy-auction-using-code";
    }
    return "";
  };

  const canContinue =
    auction &&
    auction.ends_at &&
    auction.item &&
    auction.item.price &&
    auction.item.increment &&
    auction.currency;

  return (
    <div className={styles.creationContainer}>
      <div className={cn([styles.desktopOnly, styles.creationImageContainer])}>
        <Imgix
          src={`${BidddyImage}?auto=format`}
          height={570}
          width={570}
          htmlAttributes={{
            alt: "Enter a title",
            src: `${BidddyImage}?blur=100&px=2&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.multiTitle}>
          <h3>@{selectedMarket}</h3>
          <h2>
            Add Auction
            <br /> {auction.type === MethodTypes.code ? "Settings" : "Details"}
          </h2>
        </div>
        {auction.type === MethodTypes.schedule && (
          <div className="w-full mt-8">
            <div className={styles.descriptionContainer}>
              <AuctionDescriptionTable />
            </div>
          </div>
        )}
        <div
          className={`w-full px-0 ${
            auction.type === MethodTypes.code ? "mt-8" : ""
          }`}
        >
          <div className={styles.descriptionContainer}>
            <AuctionSettingsTable />
          </div>
        </div>
        {auction.type === MethodTypes.schedule && (
          <div className="w-full px-0 mt-8">
            <div className={styles.descriptionContainer}>
              <AuctionBiddingInstructions />
            </div>
          </div>
        )}
        <div
          className={cn([
            styles.learnContent,
            { [styles.noBorderTop]: auction.type === MethodTypes.schedule },
          ])}
        >
          <AuctionButtonsContainer
            loading={localLoading}
            disabled={!canContinue}
            nextTitle={auction.type === MethodTypes.code ? "NEXT" : "NEXT"}
            nextPage={() => updateAuctionWithSettings()}
          />
          {auction.type === MethodTypes.code && (
            <Link to={getLearnPath()}>{getLearnText()}</Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionSettings;
